"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  ActionBarComponent: () => ActionBarComponent,
  components: () => components,
  customRoutes: () => customRoutes
});
module.exports = __toCommonJS(src_exports);

// src/pages/ExampleDetail.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var ExampleDetail = () => /* @__PURE__ */ (0, import_jsx_runtime.jsx)("div", { children: "Your custom page" });

// src/components/ActionBarComponent.tsx
var import_react = require("react");
var import_jsx_runtime2 = require("react/jsx-runtime");
var ActionBarComponent = (0, import_react.memo)(
  ({ defaultActions }) => /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_jsx_runtime2.Fragment, { children: defaultActions })
);

// src/index.tsx
var import_jsx_runtime3 = require("react/jsx-runtime");
var components = [];
var customRoutes = [
  {
    // route defined on the console.
    route: "/example-route/:id",
    // custom component to render
    component: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(ExampleDetail, {})
  }
];
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ActionBarComponent,
  components,
  customRoutes
});
